var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.saveClientAppLoading,
          expression: "saveClientAppLoading",
        },
      ],
    },
    [
      _c(
        "el-form",
        {
          ref: "clientAppRegisterForm",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-position": "top",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form-item",
                { attrs: { label: "Organization", prop: "organizationId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "Select" },
                      model: {
                        value: _vm.formData.organizationId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "organizationId", $$v)
                        },
                        expression: "formData.organizationId",
                      },
                    },
                    _vm._l(_vm.companies, function (company) {
                      return _c("el-option", {
                        key: company.id,
                        attrs: {
                          label: company.companyName,
                          value: company.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Name", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { round: "", type: "primary" },
              on: { click: _vm.saveClientApp },
            },
            [_vm._v(" Confirm ")]
          ),
          _c(
            "el-button",
            {
              attrs: { outline: "", round: "" },
              on: { click: _vm.handleCloseDialog },
            },
            [_vm._v(" Cancel ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }